<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <!-- v-data-table  -->
    <div class="text-left">
      <v-container fluid>
        <v-row>
          <v-col lg="3" md="3">
            <v-text-field
              v-model="panelist_ids"
              label="Enter Panelist Ids Comma Seperated"
              clearable
              @keyup.enter="panelist_ids ? getReport(page - 1) : ''"
            ></v-text-field>
          </v-col>
          <v-col lg="2" md="2">
            <v-sheet>
              <v-select
                style="font-size: 14px"
                label="select"
                v-model="selected_val"
                :hint="`${selected_val.db_val}`"
                :items="panType"
                item-text="val"
                item-value="db_val"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-sheet>
          </v-col>
          <v-col lg="2" md="2" class="pr-0">
            <v-btn
              small
              color="primary"
              class="mt-5"
              @click="getReport(page - 1)"
            >
              Filter
            </v-btn>
            <v-btn small color="grey" class="mt-5" @click="resetSearch()">
              Reset
            </v-btn>
          </v-col>
          <v-col lg="3" md="3"></v-col>
          <v-col lg="2" md="2" class="text-right">
            <v-btn
            v-show="checkmodexist()"
              small
              color="primary"
              class="mt-5"
              @click="$router.push('/consumer-score-monthly-report');"
            >
              Monthly report
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" md="3">
            <v-btn small color="primary" @click="resetit()"
            v-show="checkIfOperationExistForModule('edit')" class="mt-5">
              Reset Points
            </v-btn>
          </v-col>
          <v-col lg="3" md="3"> </v-col>
          <v-col id="show" lg="3" md="3">
            <!-- <v-btn small color="primary" class="mt-5" @click="showAll()"
              >Show All</v-btn
            > -->
          </v-col>
          <v-col lg="3" md="3" class="text-right">
            <v-btn small color="primary" class="mt-5 pl-3" @click="updateit()"
            v-show="checkIfOperationExistForModule('edit')">
              Update
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mt-5 pl-3"
              @click="exportReport()"
              v-show="checkIfOperationExistForModule('export')"
            >
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="tableWrapper">
              <v-data-table
                v-model="selected"
                :headers="headers"
                item-key="id"
                :page.sync="page"
                :sortBy.sync="sortby"
                :sort-desc.sync="sortDesc"
                :items="Row_data"
                :options.sync="options"
                :server-items-length="totalAffinity"
                :loading="isLoading"
                @item-selected="onItemSelected"
                class="elevation-1"
                hide-default-footer
                show-select
                @page-count="pageCount = $event"
              >
                <template v-slot:item.competi_id="{ item }">
                  <a
                    style="text-decoration: none"
                    @click="
                      logdialog = true;
                      getscorelog(item.competi_id);
                    "
                    >{{ item.competi_id }}</a
                  >
                </template>
                <template v-slot:item.bonus_point="{ item }">
                  <input
                    type="number"
                    style="width: 70px"
                    :value="item.bonus_point"
                    @click="checkPanelist($event, item.id)"
                    @change="updatePanelistData($event, item, 'dm')"
                    min="0"
                  />
                </template>
                <template v-slot:item.bag_remaining="{ item }">
                  <select
                    id=""
                    :value="item.bag_remaining.toString()"
                    style="width: 50px; outline-style: none"
                    @change="updatePanelistData($event, item, 'bags')"
                    @click="checkPanelist($event, item.id)"
                  >
                    <option
                      value="0"
                      :selected_bag="item.bag_remaining == 0 ? 'selected' : ''"
                    >
                      0
                    </option>
                    <option
                      value="1"
                      :selected_bag="item.bag_remaining == 1 ? 'selected' : ''"
                    >
                      1
                    </option>
                    <option
                      value="2"
                      :selected_bag="item.bag_remaining == 2 ? 'selected' : ''"
                    >
                      2
                    </option>
                    <option
                      value="3"
                      :selected_bag="item.bag_remaining == 3 ? 'selected' : ''"
                    >
                      3
                    </option>
                    <option
                      value="4"
                      :selected_bag="item.bag_remaining == 4 ? 'selected' : ''"
                    >
                      4
                    </option>
                  </select>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-row class="no-gutters">
                    <v-col>
                      <v-icon
                        la
                        dense
                        class="eyebtn ml-2"
                        @click="histData(item.id, 1)"
                      >
                        mdi-eye
                      </v-icon>
                      <!-- <a @click="histData(item.id, 1)">View</a> -->

                      <!-- <v-col class="pr-3" v-if="item.total_point >= 2000"> -->
                      <v-icon
                        v-if="item.total_point >= 2000"
                        dense
                        class="eyebtn pl-2"
                        @click="resetit(item.id)"
                      >
                        mdi-reload
                      </v-icon>
                      <!-- <a @click="resetit(item.id)">Reset</a> -->
                      <!-- </v-col> -->
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:top>
                  <v-dialog v-model="historydialog" persistent width="auto">
                    <ConsumerRecordHistory
                      :isLoad="isLoad"
                      :histitemid="histitemid"
                      :history_data="history_data"
                      :totalHistory="totalHistory"
                      :histpage="histpage"
                      :totHistRecords="totHistRecords"
                      :histoptions="histoptions"
                      :historydialog="historydialog"
                      :toggleUpdateModal="toggleUpdateModal"
                      @clsdlg="clsdlg"
                      @getHistData="histData"
                    ></ConsumerRecordHistory>
                  </v-dialog>
                </template>
              </v-data-table>
              <div class="text-center pt-4 pb-4">
                <Pagination
                  :isloading="isLoading"
                  :startRecord="startRecord"
                  :currentPage="currentPage"
                  :lastPage="lastPage"
                  :lastRecord="lastRecord"
                  :totRecords="totRecords"
                  :isCurrentPageClass="isCurrentPageClass"
                  :perpage="perpage"
                  :getLastPageClass="getLastPageClass"
                  :totPage="totPage"
                  :getPages="getPages"
                  @handlePerPage="handlePerPage"
                  @paginate="paginate"
                  @last="last"
                  @getDataByPage="getDataByPage"
                  :showPerPage="showPerPage"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- v-data-table-ends -->
    </div>
    <template>
      <v-dialog v-model="resetdialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h4 pa-16">
            <b>Do you really want to reset point(s)?</b>
          </v-card-title>

          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="resetdialogtrue()">
              Yes
            </v-btn>
            <v-btn
              color="grey"
              small
              @click="
                resetdialog = false;
                selected = [];
              "
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="updialogue" persistent max-width="425">
        <v-card>
          <v-card-title class="text-h4 pa-16">
            <b>Do you really want to update record(s)?</b>
          </v-card-title>

          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              @click="
                updialoguetrue();
                updialogue = false;
              "
            >
              Yes
            </v-btn>
            <v-btn
              color="grey"
              small
              @click="
                updialogue = false;
                selected = [];
                getReport();
              "
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="logdialog" persistent width="40%">
        <ConsumerScoreLogVue
          :data1="data1"
          :data2="data2"
          :data3="data3"
          @close="clsdlglog()"
        >
        </ConsumerScoreLogVue>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import Pagination from "./component/Pagination.vue";
import Index from "./Index.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ConsumerRecordHistory from "./component/ConsumerRecordHistory.vue";
import ConsumerScoreLogVue from "./ConsumerScoreLog.vue";
export default {
  components: {
    Index,
    Loading,
    Pagination,
    ConsumerRecordHistory,
    ConsumerScoreLogVue,
  },
  name: "ConsumerReport",
  data() {
    return {
      isLoad: true,
      updialogue: false,
      reset_flag: false,
      logdialog: false,
      data1: [],
      data2: [],
      data3: [],
      history_data: [],
      histitemid: 0,
      totalHistory: 0,
      histpage: 1,
      totHistRecords: null,
      histoptions: {},
      historydialog: false,
      toggleUpdateModal: false,
      //////table data starts here////
      selected_val: { db_val: "all", val: "All" },
      selected_bag: [],
      panType: [
        { db_val: "all", val: "All" },
        { db_val: "total_point", val: "> 2000" },
        { db_val: "bags", val: "0 Bags" },
      ],
      itemid: null,
      resetdialog: false,
      selected: [],
      options: {},
      sort: "",
      affinity_data: [],
      totalAffinity: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "Panelist ID",
          value: "competi_id",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "DM Pieces",
          value: "direct_mail_piece",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "DM Points",
          value: "direct_mail_point",
          sortable: true,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Email Pieces",
          value: "email_piece",
          sortable: true,
          width: "135",
          class: "v-data-table-header",
        },
        {
          text: "Email Points",
          value: "email_piece_point",
          sortable: true,
          width: "135",
          class: "v-data-table-header",
        },
        {
          text: "Digital Points",
          value: "digital_point",
          sortable: true,
          width: "135",
          class: "v-data-table-header",
        },
        {
          text: "Bonus Points",
          value: "bonus_point",
          sortable: true,
          width: "135",
          class: "v-data-table-header",
        },
        {
          text: "Total Points",
          value: "total_point",
          sortable: true,
          width: "135",
          class: "v-data-table-header",
        },
        {
          text: "Bags Remaining",
          value: "bag_remaining",
          sortable: true,
          width: "155",
          class: "v-data-table-header",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      categories: [],
      searchCategories: [],
      query: "",
      categ: "",
      category: "",
      deleteItems: [],
      checked_affinityid: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      productCount: [],
      affinityIds: [],
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      affinityNames: [],
      addAffinity: {
        affinityName: null,
        category: null,
        subCategory: null,
      },
      updateAffinity: {
        affinityId: null,
        affinityName: null,
        category: null,
        subCategory: null,
      },
      flag: true,
      subCategory: [],
      subcatName: [],
      subcategories: [],
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      //pagination code
      sortby: "competi_id",
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: false,
      fullPage: true,
      showPerPage: true,
      sortDesc: false,
      modal: false,
      panelist_ids: "",
      exportData: [],
      panelists_data: [],
      selectedPanelists: [],
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
    Row_data: function () {
      return this.affinity_data.map((item) => ({
        bag_remaining: item.bag_remaining ?? 0,
        bonus_point: item.bonus_point,
        competi_id: item.competi_id,
        digital_point: item.digital_point,
        direct_mail_piece: item.direct_mail_piece,
        direct_mail_point: item.direct_mail_point,
        email_piece: item.email_piece,
        email_piece_point: item.email_piece_point,
        id: item.id,
        total_point: item.total_point,
      }));
    },
    fromDate: function () {
      let date = this.date;
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      return year + "-" + month + "-01";
    },
    panelist_ids_arr: function () {
      if (this.panelist_ids == "" || this.panelist_ids == null) {
        return [];
      } else {
        let arr = this.panelist_ids.split(",");
        let new_arr = [];
        arr.forEach((id) => {
          new_arr.push(id.trim());
        });
        return new_arr;
      }
    },
    csv_name: function () {
      return "producer_scoring_monthly_report_.csv";
    },
    ///////
  },
  methods: {
    getReport(page = "") {
      let check = this;
      this.selected = [];
      this.affinity_data = [];
      let crrpage = this.page - 1;
      let consumer_report_url = check.$url("admin_report_url") + "get_report";
      // let consumer_report_url = "http://10.0.0.206:5409/get_report";

      check.isLoading = true;
      let pantype = this.selected_val.db_val;
      if (page != "") {
        crrpage = page - 1;
      }
      if (this.panelist_ids_arr.length > 0) {
        crrpage = 0;
      }
      let data = {
        competi_id: this.panelist_ids_arr,
        panelist_type: pantype,
        page: crrpage,
        sortby: this.sortby,
        sortdesc: this.sortDesc,
        per_page: this.perpage,
      };
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(consumer_report_url, data)
        .then((res) => {
          check.isLoading = false;
          if (res.data.report.length) {
            let result_data = [];
            res.data.report.forEach(function (item) {
              result_data.push(item);
            });
            this.affinity_data = result_data;
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;
            if (this.totRecords <= this.perpage) {
              this.page = 1;
            }
          } else if (res.data.errCode != 0 && this.flag) {
            check.getReport();
            this.flag = false;
          } else {
            this.affinity_data = res.data.report;
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkPanelist(e, id) {
      if (!this.selectedPanelists.includes(id)) {
        this.selectedPanelists.push(id);
        this.addToSubmit(e, id);
      }
    },
    addToSubmit(e, id) {
      let view = this;
      console.log(e);
      view.affinity_data.forEach(function (o, i) {
        if (o.id == id) {
          if (view.selectedPanelists.includes(id)) {
            view.panelists_data.push({
              id: o.id,
              bonus_point: o.bonus_point,
              bag_remaining: o.bag_remaining,
            });
          } else {
            view.panelists_data = view.panelists_data.filter(
              (o, i) => o.id != id
            );
          }
        }
      });
    },
    resetit(id = null) {
      if (id != null) {
        this.itemid = id;
        this.resetdialog = true;
      } else {
        if (this.selected.length < 1) {
          this.$swal.fire({
            position: "center-center",
            icon: "error",
            title: "Please select a record first",
          });
        } else {
          this.resetdialog = true;
        }
      }
    },
    resetdialogtrue() {
      this.isLoading = true;
      if (this.itemid != null) {
        this.resetPoints(this.itemid);
      } else {
        this.resetPoints();
      }
    },
    updateit() {
      if (this.selected.length < 1) {
        this.$swal.fire({
          position: "center-center",
          icon: "error",
          title: "Please select a record first",
        });
      } else {
        this.updialogue = true;
      }
    },

    updialoguetrue() {
      this.isLoading = true;
      this.updateRecord();
    },
    updatePanelistData(e, item, type) {
      let view = this;
      if (type == "dm") {
        let val = parseFloat(e.target.value)
        if(Math.sign(val) === -1){
          view.$swal.fire({
              position: "center-center",
              icon: "error",
              title: `Bonus Point can't be negative!!`,
            });
            this.selected = []
            // view.getReport()
            return
        }
      }
      this.selected.push(item);
      this.panelists_data.forEach((o, i) => {
        if (o.id == item.id) {
          if (type == "dm") {
            o.bonus_point = parseFloat(e.target.value);
          } else {
            o.bag_remaining = parseInt(e.target.value);
          }
        }
      });
      view.affinity_data.forEach(function (o, i) {
        if (o.id == item.id) {
          console.log("selected" + item.competi_id);
          if (type == "dm") {
            o.bonus_point = parseFloat(e.target.value);
          } else {
            o.bag_remaining = parseInt(e.target.value);
          }
        }
      });
      if (!this.selectedPanelists.includes(item.id)) {
        this.selectedPanelists.push(item.id);
        this.addToSubmit(e, item.id);
      }
    },
    updateRecord: function () {
      let check = this;
      let updatepath = check.$url("admin_report_url") + "update_bonus_points";
      // let updatepath = "http://10.0.0.206:5409/update_bonus_points";
      let data = {};
      data["data"] = this.panelists_data;
      console.warn(data);
      // this.isLoading = false;
      // return
      if (this.panelists_data.length > 0) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(updatepath, data)
          .then((res, status, xhr) => {
            if (res.data.errCode != 0) {
              this.isLoading = false;
              this.$swal.fire({
                position: "center-center",
                icon: "error",
                title: "Some Error Occurred",
              });
            } else {
              this.$swal.fire({
                position: "center-center",
                icon: "success",
                title: "Record Updated Successfully",
              });
            }
            this.getReport(this.page);
            this.selectedPanelists = [];
            this.panelists_data = [];
            this.selected = [];
            this.isLoading = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    // showAll() {
    //   this.selected_val = { db_val: "all", val: "All" };
    //   this.page = 1;
    //   this.getReport(1);
    // },
    resetPoints(id = null) {
      let check = this;
      let id_list = [];
      const resetpath = check.$url("admin_report_url") + "reset_points";
      // const resetpath = "http://10.0.0.206:5409/reset_points";
      if (id) {
        id_list.push(id);
        this.itemid = null;
      } else {
        for (const obj of this.selected) {
          if (parseInt(obj.total_point) < 2000) {
            this.isLoading = false;
            check.$swal.fire({
              position: "center-center",
              icon: "error",
              title: `Total Points are less than 2000 `,
            });
            this.selected = [];
            id_list = [];
            break;
          } else {
            id_list.push(obj.id);
          }
        }
      }
      if (id_list.length > 0) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(resetpath, { data: id_list })
          .then((res, status, xhr) => {
            if (res.data.errCode != 0) {
              this.isLoading = false;
              this.$swal.fire({
                position: "center-center",
                icon: "error",
                title: "Some Error Occurred",
              });
            } else {
              this.$swal.fire({
                position: "center-center",
                icon: "success",
                title: "Points Reset Successfully",
              });
            }
            this.isLoading = false;
            this.selected = [];
            this.getReport(this.page);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      this.resetdialog = false;
    },
    // activatedlg() {
    //   this.historydialog = true;
    // },
    histData(id, page = "") {
      // setTimeout(this.let current_active_module = view.$store.getters["Rawdata/getCurrentActiveModule"]activatedlg, 200);
      this.history_data = [];
      this.isLoad = true;
      this.historydialog = true;
      // console.warn(this.historydialog)
      this.histitemid = id;
      console.log("eye", id);
      console.log("page", page);
      if (page == "") {
        page = 1;
      }
      let check = this;
      // const historypath = check.$url("admin_report_url") + "get_report_history";
      const historypath = check.$url("admin_report_url") + "get_report_history";
      let data = {
        id: id,
        page: page - 1,
        per_page: this.perpage,
      };
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(historypath, data)
        .then((res) => {
          if (res.data.report.length) {
            let result_data = [];
            res.data.report.forEach(function (item) {
              result_data.push(item);
            });
            this.history_data = result_data;
            this.totalHistory = res.data.report_length;
            this.totHistRecords = res.data.report_length;
            if (this.totHistRecords <= this.histperpage) {
              this.histpage = 1;
            }
          } else {
            this.history_data = res.data.report;
            this.totalHistory = res.data.report_length;
            this.totHistRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //pagination methods
    exportReport() {
      let check = this;
      const exppath = check.$url("admin_report_url") + "export_data";
      console.warn(this.selected_val);
      check.isLoading = true;
      let data = {
        competi_id: this.panelist_ids_arr,
        panelist_type: this.selected_val.db_val,
        sortby: this.sortby,
        sortdesc: this.sortDesc,
      };
      this.$fetch({ requiresAuth: true, operation: "export", vueScope: check })
        .post(exppath, data)
        .then((res, status, xhr) => {
          check.isLoading = false;
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
          anchor.target = "_blank";
          let date = new Date().toISOString().split("T");
          date[1] = date[1].substring(0, 8);
          let strdate = date.join(" ");
          anchor.download = `consumer_scoring_report ${strdate}.csv`;
          anchor.click();
          //   URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    clsdlg(val) {
      this.isLoad = val;
      this.historydialog = false;
      this.history_data = [];
    },
    clsdlglog() {
      this.logdialog = false;
      this.data1 = [];
      this.data2 = [];
      this.data3 = [];
    },
    /////
    resetSearch() {
      if (
        this.panelist_ids != "" ||
        this.panelist_ids != null ||
        this.selected_val.val != "All"
      ) {
        (this.page = 1),
          (this.panelist_ids = ""),
          (this.selected = []),
          (this.selected_val.db_val = "all"),
          (this.selected_val.val = "All"),
          (this.sortby = "competi_id"),
          (this.sortDesc = false),
          (this.reset_flag = true),
          (this.perpage = 50);
        this.getReport();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    getscorelog(id) {
      let check = this;
      this.data1 = [];
      this.data2 = [];
      this.data3 = [];
      const logpath = check.$url("admin_report_url") + "get_report_log";
      // const logpath = "http://10.0.0.206:5409/get_report_log";
      let data = {
        competi_id: id,
      };
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(logpath, data)
        .then((res, status, xhr) => {
          if (res.data.report_length > 0) {
            res.data.report["reset"]["reset_by"] != "" ||
            res.data.report["reset"]["reset_date"] != ""
              ? this.data1.push(res.data.report["reset"])
              : (this.data1 = [null]);
            res.data.report["bagupdate"]["bagupdate_by"] != "" ||
            res.data.report["bagupdate"]["bagupdate_date"] != ""
              ? this.data2.push(res.data.report["bagupdate"])
              : (this.data2 = [null]);
            res.data.report["bonuspoint"]["add_bonus_point_by"] != "" ||
            res.data.report["bonuspoint"]["add_bonus_point_date"] != ""
              ? this.data3.push(res.data.report["bonuspoint"])
              : (this.data3 = [null]);
            // this.data2.push(res.data.report['bagupdate'])
            // this.data3.push(res.data.report['bonuspoint'])
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    selectAll: function () {
      let delaffinity = this.affinity;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {
            if (this.affinity_data[i].product_count == 0) {
              this.deleteItems.push(delaffinity[i][0]);
              console.log("gsdhghshsjss");
            }
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchAffinity: function () {
      this.getReport(this.page);
      console.log(this.query);
    },
    checkmodexist : function(){
      let ret = this.$utils.checkIfModuleExist(this,'Consumer Scoring Monthly Report')
      return ret
    },
    onItemSelected(items) {
      if(!items.value){
        this.panelists_data = this.panelists_data.filter((e)=>{
          if(items.item.id!=e.id){
            return true
          }
        })
      }
      else{
        return 
      }
    },
  },
  watch: {
    options: function (o) {
      let sortby = o.sortBy[0];
      let direction = o.sortDesc[0];
      if (!this.reset_flag) {
        if (sortby != undefined && direction != undefined) {
          this.sortby = sortby;
          this.sortDesc = direction;
          this.getReport();
        }
      } else {
        this.reset_flag = false;
      }
      // else{
      //  this.sortDesc = !lastdir
      // }
    },
    // "$store.state.Rawdata.current_active_module": function (o) {
    //   if (o) {
    //     this.getReport(1);
    //   }
    // },
    page: function (ob) {
      this.allSelected = false;
      this.deleteItems = [];
      //this.getReport(ob, this.query);
    },
    // affinity_data: function (val) {
    //   let obj = document.getElementById("show");
    //   if (val.length >= 1) {
    //     obj.style.visibility = "hidden";
    //   } else {    // affinity_data: function (val) {
    //   let obj = document.getElementById("show");
    //   if (val.length >= 1) {
    //     obj.style.visibility = "hidden";
    //   } else {
    //     obj.style.visibility = "visible";
    //   }
    // },
    //     obj.style.visibility = "visible";
    //   }
    // },
    // panelist_ids: function (val) {
    //   if (val == null || val == "") {
    //     this.getReport(1);
    //   }
    // },
    // exportData: function(data){
    //   if(data.length){
    //     document.getElementById('exportButton').click()
    //   }
    // }
  },
  created() {
    this.getReport();
  },
};
</script>

<!-- <style>
.eyebtn:hover::before {
  opacity: 0.08 !important;
}
</style> -->
